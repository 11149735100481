import image1 from '../assets/images/galleryImages/1.jpg'
import image2 from '../assets/images/galleryImages/2.jpg'
import image3 from '../assets/images/galleryImages/3.jpg'
import image4 from '../assets/images/galleryImages/4.jpg'
import image5 from '../assets/images/galleryImages/5.jpg'
import image6 from '../assets/images/galleryImages/6.jpg'
import image7 from '../assets/images/galleryImages/7.jpg' 
import image8 from '../assets/images/galleryImages/8.jpg' 
import image9 from '../assets/images/galleryImages/9.jpg' 
import image10 from '../assets/images/galleryImages/10.jpg' 
import image11 from '../assets/images/galleryImages/11.jpg' 
import image12 from '../assets/images/galleryImages/12.jpg' 
import image13 from '../assets/images/galleryImages/13.jpg' 

// import image14 from '../../../assets/images/slider-images/14.jpg' 
// import image15 from '../../../assets/images/slider-images/15.jpg' 
// import image16 from '../../../assets/images/slider-images/16.jpg' 
// import image17 from '../../../assets/images/slider-images/17.jpg' 
// import image18 from '../../../assets/images/slider-images/18.jpg' 
// import image19 from '../../../assets/images/slider-images/19.jpg' 
// import image20 from '../../../assets/images/slider-images/20.jpg' 
// import image21 from '../../../assets/images/slider-images/21.jpg' 
// import image22 from '../../../assets/images/slider-images/22.jpg' 
// import image23 from '../../../assets/images/slider-images/23.jpg' 
// import image24 from '../../../assets/images/slider-images/24.jpg' 
// import image25 from '../../../assets/images/slider-images/25.jpg' 
// import image26 from '../../../assets/images/slider-images/26.jpg' 
// import image27 from '../../../assets/images/slider-images/27.jpg' 


export const SliderData = [
    image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13
]